import { AdminConstants as constants } from '../constants';

const initialState = Object.freeze({
    user: null,
    loading: false,
    error: false,
});

const admincp = (state = initialState, action) => {
    switch (action.type) {
        case constants.ADMIN_USER_SELECTED: {
            return {
                ...state,
                user: action.user,
            };
        }
        case constants.ADMIN_USER_DESELECTED: {
            return {
                ...state,
                user: null,
            };
        }
        case constants.ADMIN_USER_LOADING_START: {
            return {
                ...state,
                user: null,
                loading: true,
                error: false,
            };
        }
        case constants.ADMIN_USER_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.ADMIN_USER_LOADED: {
            return {
                ...state,
                user: action.user,
                loading: false,
                error: false,
            };
        }
        default:
            return state;
    }
};

export { admincp };
