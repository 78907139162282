import { ClientConstants as constants } from '../constants';

const initialState = Object.freeze({
    clients: [],
    loading: false,
    error: false,
});

const client = (state = initialState, action) => {
    switch (action.type) {
        case constants.CLIENTS_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.CLIENTS_LOADING_START: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }
        case constants.CLIENTS_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.CLIENTS_LOADED: {
            return {
                ...state,
                clients: action.clients,
                loading: false,
                error: false,
            };
        }
        default:
            return state;
    }
};

export { client };
