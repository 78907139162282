import { WOrderDraftConstants as constants } from '../constants';

const initialState = Object.freeze({
    worderDraft: null,
    loading: false,
    error: false,
    worderDraftDeleting: false,
    wonderDraftCreating: false,
});

const worderDraft = (state = initialState, action) => {
    switch (action.type) {
        case constants.WORDER_DRAFT_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.WORDER_DRAFT_LOADING_START: {
            return {
                ...state,
                worderDraft: null,
                loading: true,
                error: false,
            };
        }
        case constants.WORDER_DRAFT_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.WORDER_DRAFT_LOADED: {
            return {
                ...state,
                worderDraft: action.worderDraft,
                loading: false,
                error: false,
            };
        }
        case constants.WORDER_DRAFT_DELETING_START: {
            return {
                ...state,
                worderDraftDeleting: true,
                error: false,
            };
        }
        case constants.WORDER_DRAFT_DELETING_ERROR: {
            return {
                ...state,
                worderDraftDeleting: false,
                error: action.error,
            };
        }
        case constants.WORDER_DRAFT_DELETED: {
            return {
                ...state,
                worderDraft: null,
                worderDraftDeleting: false,
                error: false,
            };
        }
        case constants.WORDER_DRAFT_CREATING_START: {
            return {
                ...state,
                error: false,
                worderDraftCreating: true,
            };
        }
        case constants.WORDER_DRAFT_CREATED: {
            return {
                ...state,
                worderDraft: action.worderDraft,
                worderDraftCreating: false,
                error: false,
            };
        }
        case constants.WORDER_DRAFT_CREATING_ERROR: {
            return {
                ...state,
                worderDraftCreating: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export { worderDraft };
