import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './assets/scss/index.scss';
import './assets/scss/responsive.scss';
import './assets/scss/order.responsive.scss';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ConfigProvider, App } from 'antd';
import store from './reducers';
import Routes from './routes';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: 'rgba(158, 142, 95, 1)',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                fontFamily: 'Abel',
                borderRadiusBase: '0',
                textColor: 'rgba(8, 0, 0, 1)',
                layoutBodyBackground: 'none',
                fontSizeBase: '16px',
                errorColor: 'rgba(255, 0, 0, 1)',
            },
            components: {
                Form: {
                    fontFamily: 'Abel',
                    fontSizeBase: '16px',
                },
                Button: {
                    borderRadiusLG: '0',
                    borderRadiusBase: '0',
                    fontSizeBase: '16px',
                },
                Icon: {
                    colorPrimary: 'rgba(158, 142, 95, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                },
                Input: {
                    colorPrimary: 'rgba(158, 142, 95, 1)',
                    fontFamily: 'Abel',
                    fontSizeBase: '16px',
                },
                Checkbox: {
                    colorPrimary: 'rgba(158, 142, 95, 1)',
                },
                Select: {
                    colorPrimary: 'rgba(158, 142, 95, 1)',
                },
                Tabs: {
                    itemSelectedColor: 'rgba(158, 142, 95, 1)',
                    itemActiveColor: 'rgba(158, 142, 95, 1)',
                    itemHoverColor: 'rgba(158, 142, 95, 1)',
                    inkBarColor: 'rgba(158, 142, 95, 1)',
                },
                DatePicker: {
                    fontFamily: 'Abel',
                },
                RangePicker: {
                    fontFamily: 'Abel',
                },
                Table: {
                    fontFamily: 'Abel',
                    fontSizeBase: '16px',
                },
                Navlink: {
                    fontFamily: 'Abel',
                    fontSizeBase: '16px',
                },
                Modal: {
                    borderRadiusLG: '0',
                    borderRadiusBase: '0',
                },
                Popover: {
                    borderRadiusLG: '0',
                    borderRadiusBase: '0',
                },
                Anchor: {
                    colorPrimary: 'rgba(158, 142, 95, 1)',
                    fontSizeBase: '16px',
                },
            },
        }}
    >
        <App>
            <Provider store={store}>
                <Routes />
            </Provider>
        </App>
    </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
