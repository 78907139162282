/* eslint-disable import/no-cycle */
const noItems = order => order.items.filter(i => i.id.length).length === 0;

const specifyDisabled = () => false;
const shippingDisabled = order => noItems(order);
const noShippingChosen = order => order.shippingTerms === null;
const paymentDisabled = order => shippingDisabled(order) || noShippingChosen(order);
const successDisabled = order => paymentDisabled(order) || !order.paid;

export const SalesOrderSteps = {
    specify: {
        key: 1,
        disabled: specifyDisabled,
        title: '1. Identify Your Order',
    },
    shipping: {
        key: 2,
        disabled: shippingDisabled,
        title: '2. Shipping/Pick-up Details Confirmation',
    },
    payment: {
        key: 3,
        disabled: paymentDisabled,
        title: '3. Payment',
    },
    success: {
        key: 4,
        disabled: successDisabled,
        title: '4. Order Payment Completed',
    },
};
