/* eslint-disable import/no-cycle */

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authentication } from './auth.reducer';
import { order } from './order.reducer';
import { inventory } from './inventory.reducer';
import { products } from './products.reducer';
import { client } from './client.reducer';
import { admincp } from './admin.reducer';
import { AuthActions } from '../actions/auth.actions';
import { batchOrder } from './batchOrder.reducer';
import { orderDraft } from './orderDraft.reducer';
import { worderDraft } from './worderDraft.reducer';
import { discount } from './discount.reducer';

const RootReducer = combineReducers({
    authentication,
    order,
    products,
    inventory,
    client,
    admincp,
    batchOrder,
    orderDraft,
    worderDraft,
    discount,
});

const store = configureStore({
    reducer: RootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;

const { logoutSuccess } = AuthActions;

export const dispatchLogout = () => store.dispatch(logoutSuccess());
