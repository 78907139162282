/* eslint-disable import/no-cycle */

import { api } from './_api';

export function transformIntoDiscountIntervals(discountsData) {
    if (!discountsData || !discountsData.length) return [];
    return discountsData
        .map(({ Items, DiscountBreakpoints }) => {
            const inventoryIds = Items.map(({ InventoryID }) => InventoryID.value);
            const intervals = DiscountBreakpoints.map(({ BreakQty, DiscountAmount }, index) => {
                const discountBreakpointsLength = DiscountBreakpoints.length;
                if (discountBreakpointsLength === 0 || index === discountBreakpointsLength - 1)
                    return {
                        interval: [BreakQty.value, Infinity],
                        discount: DiscountAmount.value,
                    };
                return {
                    interval: [BreakQty.value, DiscountBreakpoints[index + 1].BreakQty.value],
                    discount: DiscountAmount.value,
                };
            });
            return { inventoryIds, intervals };
        })
        .reduce((acc, { inventoryIds, intervals }) => {
            inventoryIds.forEach(id => {
                acc[id] = intervals;
            });
            return acc;
        }, {});
}

const getDiscountsByDiscountCode = () =>
    api.get('/discount/VOLUME').then(discoutns => transformIntoDiscountIntervals(discoutns));

export const DiscountService = {
    getDiscountsByDiscountCode,
};
