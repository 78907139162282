// eslint-disable-next-line import/no-cycle
import { StateOptions } from '../config';

export const RoleIds = Object.freeze({
    PUBLIC: 0,
    ADMIN: 1,
    CLIENT: 2,
    PROCLIENT: 3,
    CLIENT_LINKED: 999,
    ALL: [0, 1, 2, 3, 999],
});

export const validateEmail = email => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const UserStatus = {
    ACTIVE: 'Active',
    ON_HOLD: 'On Hold',
    CREDIT_HOLD: 'Credit Hold',
    INACTIVE: 'Inactive',
    ONE_TIME: 'One-Time',
};

export const UserRegistrationStatus = {
    ACTIVE: 1,
    UNACTIVE: 0,
};

export const RoleNames = Object.freeze({
    0: 'PUBLIC',
    1: 'ADMIN',
    2: 'CLIENT',
    3: 'PRO CLIENT',
    999: 'LINKED CLIENT',
});

export const CustomerType = {
    FURBUY: {
        value: 'FURBUY',
        label: 'Fur Buyer',
    },
    GOVMNT: {
        value: 'GOVMNT',
        label: 'Government Entity',
    },
    HUNT: {
        value: 'HUNT',
        label: 'Hunter',
    },
    OTHER: {
        value: 'OTHER',
        label: 'Domestic Livestock Trade',
    },
    TAXIDERM: {
        value: 'TAXIDERM',
        label: 'Taxidermist',
    },
    TRAP: {
        value: 'TRAP',
        label: 'Licensed Trapper',
    },
    BISON: {
        value: 'BISON',
        label: 'Domestic Bison Trade',
    },
    TRIBAL: {
        value: 'TRIBAL',
        label: 'Native American/Tribal ID',
    },
};

export const CustomerTypeOptions = Object.keys(CustomerType).map(key => CustomerType[key]);

export const showLicense = data => {
    const { customerType } = data;
    const { FURBUY, HUNT, TAXIDERM, TRAP } = CustomerType;
    return [FURBUY.value, HUNT.value, TAXIDERM.value, TRAP.value].indexOf(customerType) > -1;
};

export const showExemption = data => data.userIsExempt;

export const showBrief = data => {
    const { customerType } = data;
    const { GOVMNT, OTHER, BISON, TRIBAL } = CustomerType;
    return [GOVMNT.value, OTHER.value, BISON.value, TRIBAL.value].indexOf(customerType) > -1;
};

export const showNote = data => {
    const { customerType } = data;
    const { GOVMNT, OTHER, BISON, FURBUY, HUNT, TAXIDERM, TRAP, TRIBAL } = CustomerType;
    return (
        [
            GOVMNT.value,
            OTHER.value,
            BISON.value,
            FURBUY.value,
            HUNT.value,
            TAXIDERM.value,
            TRAP.value,
            TRIBAL.value,
        ].indexOf(customerType) > -1
    );
};

export const isDocumentRequired = data => {
    const { customerType } = data;
    let required = true;
    if (customerType === 'BISON' || customerType === 'OTHER') required = false;
    return required;
};

export const isLicenseRequired = data => {
    const { customerType, licenseState } = data;
    const { TAXIDERM } = CustomerType;
    let required = showLicense(data);

    if (licenseState && required) {
        const stateObj = Array.filterFirst(StateOptions, licenseState, 'value');
        required =
            (customerType === TAXIDERM.value && stateObj.isTaxidermyLicenseRequired) ||
            customerType !== TAXIDERM.value;
    }
    return required;
};

export const isLicenseExpDateRequired = data => !data.licenseLifetime && isLicenseRequired(data);

export const showLicenseNotRequired = data => {
    const { customerType, state } = data;
    return state && customerType && !isLicenseRequired(data);
};
