/* eslint-disable import/no-cycle */
/* eslint-disable prefer-promise-reject-errors */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { api } from './_api';
import { RoleIds, RoleNames } from '../models';
import { token as getToken } from './local.service';

dayjs.extend(utc);

const LIFETIME_EXP_DATE = '2199-12-31';
const DEFAULT_EXP_DATE = '1900-01-01';
const API_URL = process.env.REACT_APP_API;

const toQueryParams = params =>
    Object.keys(params)
        .map(k => {
            let value = params[k];
            if (Object.isObject(value)) {
                if (Object.isEmpty(value)) {
                    value = '';
                } else value = JSON.stringify(value);
            }
            if (value) {
                return `${k}=${value}`;
            }
            return '';
        })
        .join('&');

const transformerIn = data => {
    const userData = { ...data };
    let expDate = userData.licenseExpirationDate;
    let expLifetime = false;
    let role = userData.roleId;

    // TODO: This check should not be on the FE. Should be managed on the BE ideally
    if (userData.roleId === RoleIds.CLIENT && userData.acumaticaCustomerId) {
        role = RoleIds.CLIENT_LINKED;
    }

    const roleName = RoleNames[role];

    if (new Date(expDate).getTime() === new Date(LIFETIME_EXP_DATE).getTime()) {
        expDate = undefined;
        expLifetime = true;
    }
    if (
        expDate === DEFAULT_EXP_DATE ||
        (typeof expDate === 'string' && expDate.startsWith(DEFAULT_EXP_DATE))
    ) {
        expDate = undefined;
    }

    const certFileId = userData.acumaticaExemptionCertFileId;
    if (
        certFileId &&
        userData.acumaticaExemptionCertFile &&
        userData.acumaticaExemptionCertFile.id === certFileId
    ) {
        userData.acumaticaExemptionCertFileId = [
            {
                uid: certFileId,
                id: certFileId,
                name: userData.acumaticaExemptionCertFile.name,
                url: `${API_URL}/file/${certFileId}?token=${getToken()}`,
            },
        ];
    }
    userData.userIsExempt = userData.exemptionCertNumber ? 1 : 0;
    const licenseExpDate = expDate ?? undefined;
    return {
        ...userData,
        role,
        roleName,
        licenseExpirationDate: licenseExpDate,
        licenseLifetime: expLifetime,
    };
};

const transformerOut = data => {
    const userData = { ...data };

    const { file } = userData.acumaticaExemptionCertFileId || { file: null };
    if (file && file.response) {
        userData.acumaticaExemptionCertFileId = file.response.id;
    } else {
        userData.acumaticaExemptionCertFileId = undefined;
    }

    if (userData.licenseLifetime) {
        userData.licenseExpirationDate = LIFETIME_EXP_DATE;
    }

    if ('userIsExempt' in userData) {
        if (!userData.userIsExempt) {
            userData.acumaticaExemptionCertFileId = null;
            userData.exemptionCertNumber = '';
        }
    }
    return userData;
};

const login = credentials =>
    api
        .post('/token', credentials)
        .then(data => transformerIn(data))
        .catch(error => {
            if (error.code === 401) {
                return Promise.reject({ message: 'Invalid E-mail or Password' });
            }
            return Promise.reject(error);
        });

const logout = () => api.delete('/token').catch(error => Promise.reject(error));

const signUpTokenValidate = token =>
    api.put(`/registerValidation?token=${token}`).then(data => ({
        ...data,
        roleId: RoleIds.CLIENT,
    }));

const passwordChangeRequest = email => api.post('/forgotPasswordRequest', { email });

const passwordChange = data => api.post('/forgotPasswordValidate', data);

const checkForValidToken = token =>
    api.get(`/checkForValidToken/forgotPassword/${token}`).then(res => transformerIn(res));

const register = data => api.post('/users', data);

const get = id => api.get(`/users/${id}`).then(res => transformerIn(res));

const getUserForAdmin = id => api.get(`/users/${id}/brief`).then(res => transformerIn(res));

const getLoggedUser = () => get('me');

const update = (id, data) =>
    api.put(`/users/${id}`, transformerOut(data)).then(res => transformerIn(res));

const remove = id => api.delete(`/users/${id}`);

const updateLoggedUser = data => update('me', data);

/* eslint-disable camelcase */
const list = (page = 1, per_page = 500, filters = {}, order_by = 'id+DESC') => {
    const params = toQueryParams({
        page,
        per_page,
        order_by,
        filters,
    });

    return api.get(`/users?${params}`);
};
/* eslint-enable camelcase */

const link = (id, acumaticaCustomerId) =>
    api.put(`/users/${id}/relink/${acumaticaCustomerId}`).then(res => transformerIn(res));

const sendActivation = id => api.get(`/users/${id}/resend-activation`);

const changeEmail = (id, data) => api.put(`/users/${id}/change-email`, data).then(transformerIn);

export const AuthService = {
    get,
    getUserForAdmin,
    update,
    remove,
    changeEmail,
    login,
    logout,
    register,
    getLoggedUser,
    updateLoggedUser,
    signUpTokenValidate,
    passwordChangeRequest,
    passwordChange,
    checkForValidToken,
    transformerIn,
    transformerOut,
    list,
    link,
    sendActivation,
};
