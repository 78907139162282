import { OrderConstants as constants } from '../constants';
// eslint-disable-next-line import/no-cycle
import { OrderStatus, ORDER_SERVICE_TYPES } from '../models';

const initialState = Object.freeze({
    orders: [],
    ordersLoading: false,
    items: [],
    pickup: false,
    payments: [],
    serviceTotal: false,
    status: OrderStatus.enRoute,
    customerType: undefined,
    hold: false,
    laceyAct: false,
    refresh: 0,
    marks: [],
    volumeDiscounts: [],
    orderError: false,
    orderLoading: false,
    orderUpdating: false,
    orderCreating: false,
    batchOrderLoading: false,
    shippingTerms: null,
    billToAddress: {
        defaultBillToAddress: {},
    },
    serviceType: ORDER_SERVICE_TYPES.regular.key,
    order: null,
});

const order = (state = initialState, action) => {
    switch (action.type) {
        case constants.ORDER_CLEAR: {
            return {
                ...initialState,
                orderLoading: state.orderLoading,
            };
        }

        case constants.ORDER_SELECTED: {
            return {
                ...state,
                order: action.order,
            };
        }
        case constants.ORDER_DESELECTED: {
            return {
                ...state,
                order: null,
            };
        }
        case constants.ORDERS_LOADING_START: {
            return {
                ...state,
                ordersLoading: true,
                error: false,
            };
        }
        case constants.ORDERS_LOADING_ERROR: {
            return {
                ...state,
                ordersLoading: false,
                error: action.error,
            };
        }
        case constants.ORDERS_LOADED: {
            return {
                ...state,
                orders: action.orders,
                ordersLoading: false,
                error: false,
            };
        }

        case constants.ORDER_LOADING_START: {
            return {
                ...state,
                orderLoading: true,
                error: false,
            };
        }
        case constants.ORDER_LOADING_ERROR: {
            return {
                ...state,
                orderLoading: false,
                orderError: action.error,
            };
        }
        case constants.ORDER_LOADED: {
            return {
                ...state,
                ...action.order,
                orderLoading: false,
                orderLoaded: true,
                refresh: state.refresh + 1,
                error: false,
            };
        }
        case constants.ORDER_CREATING_START: {
            return {
                ...initialState,
                orderCreating: true,
                error: false,
            };
        }
        case constants.ORDER_CREATED: {
            return {
                ...state,
                orderCreating: false,
                error: false,
            };
        }
        case constants.ORDER_CREATING_ERROR: {
            return {
                ...initialState,
                orderCreating: false,
                error: action.error,
            };
        }
        case constants.ORDER_UPDATING_START: {
            return {
                ...state,
                orderUpdating: true,
                error: false,
            };
        }
        case constants.ORDER_UPDATING_ERROR: {
            return {
                ...state,
                orderUpdating: false,
                error: action.error,
            };
        }
        case constants.ORDER_PAID:
        case constants.ORDER_UPDATED: {
            const newValue = action.order && action.order.detailsConfirmed;
            const detailsConfirmed = newValue === undefined ? state.detailsConfirmed : newValue;
            return {
                ...state,
                ...action.order,
                orderUpdating: false,
                refresh: state.refresh + 1,
                error: false,
                detailsConfirmed,
            };
        }
        default:
            return state;
    }
};

export { order };
