const getterSetter =
    name =>
    (...args) => {
        if (!args.length) {
            return localStorage.getItem(name);
        }

        if (args[0] === false) {
            return localStorage.removeItem(name);
        }
        return localStorage.setItem(name, args[0]);
    };

export const token = getterSetter('token');

export const LocalService = {
    email: getterSetter('email'),
    provider: getterSetter('provider'),
    token,
};

export const documentUrl = (documentType, key) =>
    `${process.env.REACT_APP_API}/${documentType}/${key}/pdf?token=${token()}`;
