/* eslint-disable import/no-cycle */
import { api } from './_api';
import { transformerIn, transformerOut, OrderFields } from './order.service';

const get = (orderId, customerId) =>
    api.get(`sales-order/${orderId}/${customerId}`).then(res => transformerIn(OrderFields, res));

const changeEmail = (orderId, customerId, data) =>
    api
        .put(`sales-order/${orderId}/${customerId}/change-email`, data)
        .then(res => transformerIn(OrderFields, res));

const updateSo = (orderId, customerId, values) =>
    new Promise((resolve, reject) => {
        const data = transformerOut(OrderFields, values);
        api.put(`/sales-order/${orderId}/${customerId}`, data).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        );
    });

export const SalesOrderService = {
    get,
    changeEmail,
    updateSo,
};
