// eslint-disable-next-line import/no-cycle
import { api } from './_api';

const getChildren = (data, parentIds) =>
    data.filter(item => parentIds.indexOf(item.ParentCategoryID.value) > -1);

const getProducts = url =>
    api.get(url).then(products => {
        const { data } = products;

        const species = getChildren(data, [1]);
        const categories = getChildren(
            data,
            species.map(x => x.CategoryID.value)
        );

        let speciesItems = [];
        categories.forEach(item => {
            const parents = item.Path.value.split('/');
            const members = item.Members.filter(m => m.InventoryID && m.InventoryID.value).map(
                m => ({
                    value: m.InventoryID.value,
                    label: m.Description.value,
                    specie: parents[2],
                    category: parents[3],
                })
            );
            speciesItems = [...speciesItems, ...members];
        });

        const bvrs = ['BEAVERXLG', 'BEAVERLG', 'BEAVERSM'];
        speciesItems.sort((a, b) => {
            if ([a.value, b.value].every(v => bvrs.includes(v))) {
                return bvrs.indexOf(a.value) < bvrs.indexOf(b.value) ? -1 : 1;
            }
            return a.value < b.value ? -1 : 1;
        });

        const speciesWithChildren = [...new Set(speciesItems.map(x => x.specie))].map(x => ({
            value: x,
            label: x,
        }));

        speciesWithChildren.sort((a, b) => (a.value < b.value ? -1 : 1));

        return { speciesItems, species: speciesWithChildren };
    });

const getAllProducts = () => getProducts('/products');
const getFrozenProducts = () => getProducts('/products/frozen');
const getNonFrozenProducts = () => getProducts('/products/nonfrozen');
const getNonStockItems = () => api.get('/nonStockProducts').then(products => products.data);

export const ProductService = {
    getAllProducts,
    getFrozenProducts,
    getNonFrozenProducts,
    getNonStockItems,
};
