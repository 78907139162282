/* eslint-disable import/no-cycle */
/* eslint no-param-reassign: "error" */
import dayjs from 'dayjs';
import { api, TDApi } from './_api';
import store from '../reducers';
import { token } from './local.service';
import {
    ORDER_SERVICE_TYPES,
    ITEM_SERVICE_TYPES,
    ORDER_MARK_NAMES,
    OrderItemDefault,
    OrderStatus,
    OrderSteps,
    OrderItemStatus,
    CANCELED,
    BEAVERM20,
    BEAVERM20_SERVICE,
    WP_DISCOUNT_5,
    WP_DISCOUNT_15,
    ORDER_TYPES,
} from '../models';

const API_URL = process.env.REACT_APP_API;
const propGetterSetter = value => value;
const propGetValue = prop => prop.value;
const propSetValue = value => ({ value });

export const OrderItemFields = {
    id: {
        prop: 'id',
        propIn: propGetterSetter,
    },
    lineNbr: {
        prop: 'LineNbr',
        propIn: propGetValue,
    },
    qty: {
        prop: 'OrderQty',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    priceUnitDiscounted: {
        prop: 'DiscountedUnitPrice',
        propIn: propGetValue,
    },
    priceUnit: {
        prop: 'UnitPrice',
        propIn: propGetValue,
    },
    curyLineAmt: {
        prop: 'CuryLineAmt',
        propIn: propGetValue,
    },
    priceTotal: {
        prop: 'ExtendedPrice',
        propIn: ({ value }, dataIn) => {
            if ('curyLineAmt' in dataIn) {
                return dataIn.curyLineAmt;
            }
            return value;
        },
    },
    marks: {
        prop: 'note',
        propIn: (value, dataIn) => {
            const { MARK1, MARK2 } = ORDER_MARK_NAMES;

            if (!value) return [];
            const currValue = value.value;
            if (currValue === '') return [];

            const rr = currValue.split('\n');
            const valueToArray = (rows, startsWith) => {
                const row = rows.filter(r => r.toLowerCase().indexOf(startsWith) > -1);
                if (row.length) {
                    const items = row[0].split(':');
                    if (items.length > 1) {
                        return items[1].split(',').map(i => i.trim());
                    }
                }
                return [];
            };
            const mark1 = valueToArray(rr, 'client');
            const mark2 = valueToArray(rr, 'govt');

            const rows = Array.create(dataIn.qty).map(index => ({
                [MARK1]: mark1[index],
                [MARK2]: mark2[index],
            }));

            return rows;
        },
        propOut: marks => {
            const { MARK1, MARK2 } = ORDER_MARK_NAMES;

            const arrayToValue = (items, prop) => items.map(item => item[prop]).join(',');

            const mark1 = arrayToValue(marks, MARK1);
            const mark2 = arrayToValue(marks, MARK2);

            if ((mark1.length === mark2.length) === marks.length - 1) {
                return '';
            }

            return { value: `Client marks: ${mark1}\nGovt marks: ${mark2}` };
        },
    },
    speciesItemDescription: {
        prop: 'LineDescription',
        propIn: propGetValue,
    },
    speciesItem: {
        prop: 'InventoryID',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    serviceType: {
        prop: 'ServiceType',
        propOut: value => ({
            value:
                ORDER_SERVICE_TYPES[value] !== undefined
                    ? ORDER_SERVICE_TYPES[value].acumaticaType
                    : value,
        }),
    },
    specieObj: {
        prop: 'InventoryID',
        propIn: ({ value }, data) => {
            const { speciesItem, speciesItemDescription } = data;

            const {
                products: { allProducts },
            } = store.getState();

            let specieObj = [];
            if (Object.keys(allProducts).length > 0) {
                specieObj = allProducts.speciesItems.filter(u => u.value === speciesItem);
            }
            let res;
            if (specieObj.length) {
                [res] = specieObj;
            } else {
                let specie = '';
                const arr = speciesItemDescription.split(' ');
                if (arr.length) {
                    [specie] = arr;
                }
                res = { value, label: speciesItemDescription, specie, category: '-' };
            }
            return res;
        },
    },
    specie: {
        prop: 'InventoryID',
        propIn: (value, { specieObj }) => specieObj.specie || '',
    },
    category: {
        prop: 'InventoryID',
        propIn: (value, { specieObj }) => specieObj.category || '',
    },
    status: {
        prop: 'ProdStatus',
        // propIn: ({ value }) =>
        // OrderItemStatus[(value || 'ready').toLowerCase()] || OrderItemStatus.ready,
        propIn: field => {
            if ((Array.isArray(field) && field.length === 0) || !field) {
                return OrderItemStatus.ready;
            }
            const { value } = field;
            return OrderItemStatus[(value || 'ready').toLowerCase()] || OrderItemStatus.ready;
        },
    },
    delete: {
        prop: 'delete',
        propIn: () => false,
        propOut: propGetterSetter,
    },
};

export const transformerIn = (fieldset, data) => {
    const dataIn = {};
    Object.keys(fieldset).forEach(nameIn => {
        const { prop, propIn = propGetValue } = fieldset[nameIn];
        if (prop in data) {
            const propValue = data[prop];
            dataIn[nameIn] = propIn(propValue, dataIn);
        }
    });

    const beaverMoreThan20 = {
        id: BEAVERM20_SERVICE.name,
        qty: 0,
        specie: BEAVERM20,
        speciesItem: BEAVERM20_SERVICE.name,
        speciesItemDescription: BEAVERM20_SERVICE.description,
        category: 'Fur Dressing',
        marks: [],
        types: [],
    };

    if (dataIn && dataIn.items && dataIn.items.length) {
        const newItems = dataIn.items.map(i => {
            if (i.specie === BEAVERM20) {
                beaverMoreThan20.qty += i.qty;
                beaverMoreThan20.types.push(i);
                beaverMoreThan20.marks = [...beaverMoreThan20.marks, ...i.marks];
                return false;
            }
            return i;
        });
        if (beaverMoreThan20.types.length) newItems.push(beaverMoreThan20);
        return Object.cleanUp({ ...dataIn, items: [...newItems.filter(Boolean)] });
    }

    return Object.cleanUp(dataIn);
};

export const transformerItemsInBatch = (fieldset, data) => {
    const dataIn = {};
    Object.keys(fieldset).forEach(nameIn => {
        let { prop } = fieldset[nameIn];
        const { propIn = propGetValue } = fieldset[nameIn];
        prop = prop.charAt(0).toLowerCase() + prop.slice(1);
        if (prop in data) {
            const propValue = data[prop];
            dataIn[nameIn] = propIn(propValue, dataIn);
        }
    });

    const beaverMoreThan20 = {
        id: BEAVERM20_SERVICE.name,
        qty: 0,
        specie: BEAVERM20,
        speciesItem: BEAVERM20_SERVICE.name,
        speciesItemDescription: BEAVERM20_SERVICE.description,
        category: 'Fur Dressing',
        marks: [],
        types: [],
    };

    if (dataIn && dataIn.items && dataIn.items.length) {
        const newItems = dataIn.items.map(i => {
            if (i.specie === BEAVERM20) {
                beaverMoreThan20.qty += i.qty;
                beaverMoreThan20.types.push(i);
                beaverMoreThan20.marks = [...beaverMoreThan20.marks, ...i.marks];
                return false;
            }
            return i;
        });
        if (beaverMoreThan20.types.length) newItems.push(beaverMoreThan20);
        return Object.cleanUp({ ...dataIn, items: [...newItems.filter(Boolean)] });
    }

    return Object.cleanUp(dataIn);
};
export const transformerOut = (Definitions, dataRaw) => {
    const data = Object.cleanUp(dataRaw);
    const items = [];

    if (data.items && data.items.length) {
        data.items.forEach(i =>
            (i.speciesItem === BEAVERM20_SERVICE.name || i.id === BEAVERM20_SERVICE.name) &&
            i.types &&
            i.types.length
                ? i.types.map(t => {
                      const beaverItem = { ...t };
                      if (!beaverItem.qty) {
                          if (!beaverItem.$new) {
                              beaverItem.delete = true;
                              return items.push(beaverItem);
                          }
                          if (beaverItem.$new) return null;
                      }
                      return items.push(t);
                  })
                : items.push(i)
        );
    }
    const sortedItems = items.sort((a, b) => !!b.delete - !!a.delete);
    // @ TO DELETE ITEMS PROPARLY THEY NEED TO BE AT THE BEGINING OF THE DETAILS ARRAY
    data.items = [...sortedItems];

    const dataOut = {};
    Object.keys(Definitions).forEach(nameIn => {
        const { prop, propOut } = Definitions[nameIn];

        if (propOut && nameIn in data && data[nameIn] !== undefined) {
            const propValue = data[nameIn];
            dataOut[prop] = propOut(propValue, dataOut);
        }
    });
    return Object.cleanUp(dataOut);
};

export const transformerInventoryToOrder = dataRaw => {
    const groupBy = (data, key) =>
        data.reduce((result, currentValue) => {
            const currValKey = currentValue[key];
            if (!result[currValKey]) {
                result[currValKey] = [];
            }

            // remap the Client/Govt tags to the expected field names
            // for the transformer helper function
            // A spread of currentvalue is needed, because it can be comming from the redux store and state there can not be change outside of a reducer.
            result[currValKey].push({
                ...currentValue,
                [ORDER_MARK_NAMES.MARK1]: currentValue.clientMark,
                [ORDER_MARK_NAMES.MARK2]: currentValue.governmentTag,
            });
            return result;
        }, {});
    const groupedByInventoryId = groupBy(dataRaw, 'inventoryId');

    const orderDetails = [];
    const trackingInfo = {};

    Object.keys(groupedByInventoryId).forEach(inventoryId => {
        trackingInfo[inventoryId] = [];
        trackingInfo[inventoryId].push(
            groupedByInventoryId[inventoryId].map(a => ({
                tag: a.skinTag,
                bagTag: a.bagTag,
                clientName: a.clientName,
                clientMark: a.clientMark,
                governmentTag: a.governmentTag,
                notes: a.notes,
            }))
        );

        const serviceType = [...new Set(dataRaw.map(item => item.serviceType))][0];
        orderDetails.push({
            OrderQty: { value: groupedByInventoryId[inventoryId].length },
            InventoryID: { value: inventoryId },
            ServiceType: { value: ITEM_SERVICE_TYPES[serviceType].acumaticaType },
            delete: false,
            Tracking: { value: true },
        });
    });

    const order = { Details: orderDetails, trackingInfo };
    return Object.cleanUp(order);
};

export const prepareTrackingObject = (order, tagInfo) => {
    const groupBy = (data, key) =>
        data.reduce((result, currentValue) => {
            if (!result[currentValue[key]]) {
                result[currentValue[key]] = [];
            }
            result[currentValue[key]].push(currentValue);
            return result;
        }, {});
    const orderByInventoryId = groupBy(order.tracking, 'inventoryId');

    const tracking = [];
    Object.keys(orderByInventoryId).forEach(inventoryId => {
        const tagRecords = tagInfo[inventoryId][0];
        tracking.push(
            ...orderByInventoryId[inventoryId].map((tr, i) => ({
                id: tr.id,
                Tag: { value: tagRecords[i].tag },
                BagTag: { value: tagRecords[i].bagTag },
                ClientMark: { value: tagRecords[i].clientMark },
                CustomerName: { value: tagRecords[i].clientName },
                GovMark: { value: tagRecords[i].governmentTag },
                note:
                    tagRecords[i].notes === null ? null : `Item user note: ${tagRecords[i].notes}`,
            }))
        );
    });
    return tracking;
};

const calculateOrderStatus = (status, order) => {
    const {
        authentication: { user },
    } = store.getState();

    const { express, regular, guaranteed } = ORDER_SERVICE_TYPES;
    const userFields = [
        user[express.userField],
        user[regular.userField],
        user[guaranteed.userField],
    ];
    const isDefaultOrder = userFields.includes(order.number);

    const isCanceled = status.value === CANCELED && {
        title: 'Canceled',
        color: '#ff2400',
        textColor: 'white',
    };

    // Go to the prepayment section that the user has reached
    // Allow editing of previous section
    if (
        isDefaultOrder ||
        order.total === 0 ||
        (!order.paid && !order.payByCheck && !order.printedDate)
    ) {
        const activeSteps = Object.keys(OrderSteps)
            .map(key => OrderSteps[key])
            .filter(item => !item.disabled(order))
            .map(item => item.key);

        return {
            ...OrderStatus.uncomplete,
            step: Math.max(...activeSteps),
            ...isCanceled,
            // step:  on current step?
        };
    }

    // * All previous sections become readonly

    // Go to number of boxes page
    if (!order.numberOfBoxes) {
        return {
            ...OrderStatus.shipmentUnconfirmed,
            ...isCanceled,
        };
    }

    const itemsArray = order.items || [{ status: OrderItemStatus.ready }];
    const itemsStatuses = itemsArray.map(i => i.status);
    const minItemsStatus = Math.min(...itemsStatuses);
    const maxItemsStatus = Math.max(...itemsStatuses);

    // Go to the document printing page
    if (maxItemsStatus <= OrderItemStatus.ready && !order.printedDocs) {
        return {
            ...OrderStatus.shipmentUnconfirmed,
            ...isCanceled,
        };
    }

    // Go to tracking page => En Route to MM&T
    if (maxItemsStatus <= OrderItemStatus.ready) {
        return {
            ...OrderStatus.enRoute,
            ...isCanceled,
        };
    }

    // Go to tracking => goods completed page
    // Text displayed should be dependent on the whatever user has selected pick up or delivery
    if (minItemsStatus >= OrderItemStatus.complete) {
        return {
            ...OrderStatus.complete,
            ...isCanceled,
        };
    }

    // Go to tracking -> goods at mmt page
    // status Hold
    // Show hold reason, if hold reason is other, show description
    if (order.hold) {
        return {
            ...OrderStatus.atmmt,
            description: order.hold,
            ...isCanceled,
        };
    }

    // Go to tracking -> goods at MMT, status Goods in wherehouse
    // || ? all received || abandoned item status?
    const allowed = [OrderItemStatus.received, OrderItemStatus.discarded];
    if (itemsStatuses.every(item => allowed.includes(item))) {
        return {
            ...OrderStatus.atmmt,
            description: 'In warehouse',
            ...isCanceled,
        };
    }

    // Go to tracking -> goods at MMT
    // Status goods in processing
    return {
        ...OrderStatus.atmmt,
        description: 'In processing',
        ...isCanceled,
    };
};

const LIFETIME_EXP_DATE = '2199-12-31';
const DEFAULT_EXP_DATE = '1900-01-01';

const checkLicenseLifetimeExp = value => dayjs(value).format('YYYY-MM-DD') === LIFETIME_EXP_DATE;

const findLicenseExpirationDate = value => {
    const date = dayjs(value);
    const formatedDate = date.format('YYYY-MM-DD');

    if (formatedDate === DEFAULT_EXP_DATE || formatedDate === LIFETIME_EXP_DATE) {
        return undefined;
    }

    return dayjs(formatedDate);
};

export const OrderFields = {
    id: {
        prop: 'id',
        propIn: propGetterSetter,
        propOut: propGetterSetter,
    },
    batchId: {
        prop: 'BatchID',
        propIn: propGetValue,
        propOut: value => ({
            value,
        }),
    },
    number: {
        prop: 'OrderNbr',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    customerId: {
        prop: 'CustomerID',
        propIn: propGetValue,
        propOut: propSetValue,
    },

    invoices: {
        prop: 'Shipments',
        propIn: (data = []) =>
            data.reduce((acc, { InvoiceNbr: { value } }) => {
                if (value) acc.push(value);
                return acc;
            }, []),
    },
    date: {
        prop: 'Date',
        propIn: propGetValue,
    },
    type: {
        prop: 'OrderType',
        propIn: propGetValue,
    },
    total: {
        prop: 'OrderTotal',
        propIn: propGetValue,
    },
    itemsAll: {
        prop: 'Details',
        propIn: (value = []) => value.map(item => transformerIn(OrderItemFields, item)),
    },
    payments: {
        prop: 'Payments',
        propIn: (value = []) => value,
    },
    unpaidBalance: {
        prop: 'Totals',
        propIn: value => value.UnpaidBalance && value.UnpaidBalance.value,
    },
    volumeDiscounts: {
        prop: 'DiscountDetails',
        propIn: (value = []) =>
            value
                .filter(item => item.DiscountCode.value === 'PROVOL')
                .map(({ DiscountDesc, SequenceID, DiscountAmount, DiscountableQty }, idx) => {
                    const amount = parseFloat(DiscountAmount.value, 10);
                    const qty = DiscountableQty.value;
                    const description = DiscountDesc
                        ? DiscountDesc.value
                        : `${SequenceID.value} Combined Volume Discount`;
                    return {
                        idx,
                        description,
                        amount,
                        perUnit: amount / qty,
                    };
                }),
    },
    taxes: {
        prop: 'TaxDetails',
        propIn: (value = []) => {
            const taxes = value.filter(item => item.TaxID.value === '83336');
            if (!taxes.length) return 'EXEMPT';
            return taxes.reduce((acc, item) => acc + parseFloat(item.TaxAmount.value, 10), 0);
        },
    },
    shippingTerms: {
        prop: 'ShippingSettings',
        propIn: ({ ShippingTerms = {} }) => {
            const terms = ShippingTerms.value;
            if (!terms) return null;
            return terms !== 'PICKUP';
        },
        propOut: value => ({
            ShippingTerms: { value: value === false ? 'PICKUP' : 'CCSHIP' },
        }),
    },
    locationId: {
        prop: 'LocationID',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    shippingTermsIsTBD: {
        prop: 'ShippingSettings',
        propIn: ({ ShippingTerms = {} }) =>
            ShippingTerms.value === 'WOCALC' ||
            ShippingTerms.value === 'SOCALC' ||
            ShippingTerms.value === 'CCSHIP',
    },
    shippingTotal: {
        prop: 'Totals',
        propIn: (value, dataIn) => {
            if (dataIn.shippingTermsIsTBD) {
                return 'TBD';
            }
            return value.Freight && value.Freight.value;
        },
    },
    customerType: {
        prop: 'CustomerType',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    license: {
        prop: 'License',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    licenseExpirationDate: {
        prop: 'LicenseExp',
        propIn: ({ value }) => findLicenseExpirationDate(value),
        propOut: value => {
            if (value === '') {
                return { value: LIFETIME_EXP_DATE };
            }
            return { value };
        },
    },
    licenseLifetime: {
        prop: 'LicenseExp',
        propIn: ({ value }) => checkLicenseLifetimeExp(value),
    },
    licenseStatus: {
        prop: 'LicenseStatus',
        propIn: ({ value }) => !!value,
        propOut: value => ({ value: value ? 'YES' : 'PENDING' }),
    },
    detailsConfirmed: {
        prop: 'LicenseStatus',
        propIn: (value, dataIn) => {
            if (dataIn.type === ORDER_TYPES.WO) {
                return true;
            }
            return !value;
        },
    },
    licenseState: {
        prop: 'LicenseState',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    licenseFiles: {
        prop: 'files',
        propIn: value =>
            value.map(item => ({
                uid: item.id,
                name: item.filename.split('\\').pop(),
                url: `${API_URL}/file/${item.id}?token=${token()}`,
            })),
    },
    licenseNotes: {
        prop: 'note',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    laceyAct: {
        prop: 'LaceyAct',
        propIn: propGetValue,
        // propOut: value => value && value,
        propOut: propSetValue,
    },
    numberOfBoxes: {
        prop: 'AmountBoxes',
        propIn: ({ value }) => value && parseInt(value, 10),
        // propOut: value => value && parseInt(value, 10),
        propOut: propSetValue,
    },
    printedDocs: {
        prop: 'PrintedDocs',
        propIn: propGetValue,
        propOut: propSetValue,
    },
    printedDate: {
        prop: 'PrintedDate',
        propOut: propSetValue,
        propIn: propGetValue,
    },
    'No License': {
        prop: 'NoLicense',
        propIn: propGetValue,
    },
    'No Tags': {
        prop: 'NoTags',
        propIn: propGetValue,
    },
    shipToAddressOverride: {
        prop: 'ShipToAddressOverride',
        propOut: propSetValue,
        propIn: propGetValue,
    },
    shipToContactOverride: {
        prop: 'ShipToContactOverride',
        propOut: propSetValue,
        propIn: propGetValue,
    },
    shipToContact: {
        prop: 'ShipToContact',
        propIn: ({ BusinessName, Email, Phone1 }) => ({
            companyName: BusinessName.value,
            email: Email.value,
            phoneNumber: Phone1.value,
        }),
    },
    shipToAddress: {
        prop: 'ShipToAddress',
        propIn: ({ AddressLine1, AddressLine2, City, State, PostalCode, Country }) => ({
            address1: AddressLine1.value,
            address2: AddressLine2.value,
            city: City.value,
            state: State.value,
            zip: PostalCode.value,
            country: Country.value,
        }),
    },
    billToAddress: {
        prop: 'BillToAddress',
        propIn: ({ AddressLine1, AddressLine2, City, State, PostalCode, Country }) => ({
            sameAsShipping: true,
            defaultBillToAddress: {
                address1: AddressLine1.value,
                address2: AddressLine2.value,
                city: City.value,
                state: State.value,
                zip: PostalCode.value,
                country: Country.value,
            },
        }),
        propOut: ({ address1, address2, city, state, zip, country }) => ({
            AddressLine1: { value: address1 },
            AddressLine2: { value: address2 },
            City: { value: city },
            State: { value: state },
            PostalCode: { value: zip },
            Country: { value: country },
        }),
        // propIn: (value, dataIn) => ({
        //     sameAsShipping: true,
        //     defaultBillToAddress: dataIn.shipToAddress,
        // }),
    },
    'Missing Box': {
        prop: 'MissingBox',
        propIn: propGetValue,
    },
    Other: {
        prop: 'OtherHoldDesc',
        propIn: propGetValue,
    },
    hold: {
        prop: 'Hold',
        propIn: ({ value }, dataIn) => {
            if (!value) return false;

            const PossibleHoldReasons = ['No License', 'No Tags', 'Missing Box', 'Other'];
            const HoldReason = PossibleHoldReasons.map(reason => {
                if (dataIn[reason] === true) return reason;
                if (dataIn[reason]) return dataIn[reason];
                return null;
            }).filter(item => !!item);
            return `On Hold ${HoldReason.length ? ` - ${HoldReason.join(', ')}` : ''}`;
        },
    },
    prodStatus: {
        prop: 'Status',
        propIn: prop => prop.value.toLowerCase(),
    },

    serviceType: {
        prop: 'ServiceType',
        propIn: ({ value }) => {
            const res = Object.values(ORDER_SERVICE_TYPES).find(
                item => item.acumaticaType === value
            );
            return res ? res.name : ORDER_SERVICE_TYPES.regular.name;
        },
    },
    items: {
        prop: 'Details',
        propIn: (value, dataIn) => {
            // Remove Special Service items
            const ServiceItemsIDS = [
                ORDER_SERVICE_TYPES.guaranteed.key,
                ORDER_SERVICE_TYPES.express.key,
                'FREIGHT',
                WP_DISCOUNT_5,
                WP_DISCOUNT_15,
            ];
            const res = dataIn.itemsAll.filter(item => !ServiceItemsIDS.includes(item.speciesItem));
            // Add empty item
            if (!res.length) {
                res.push({ ...OrderItemDefault, id: -999, $new: true });
            }
            return res;
        },
        propOut: value => {
            const items = [...value];
            return items.map(item => {
                const t = transformerOut(OrderItemFields, item);
                if (!item.$new) t.id = item.id;
                return t;
            });
        },
    },
    serviceTotal: {
        prop: 'Details',
        propIn: (value, dataIn) => {
            const ItemExpress = dataIn.itemsAll.find(
                i => i.speciesItem === ORDER_SERVICE_TYPES[dataIn.serviceType].key
            );
            return ItemExpress ? ItemExpress.priceTotal : false;
        },
    },
    lineItemsTotal: {
        prop: 'Totals',
        propIn: value => {
            const { LineTotalAmount } = value;
            return LineTotalAmount && LineTotalAmount.value;
        },
    },
    itemsTotal: {
        prop: 'Details',
        propIn: (value, dataIn) => {
            let lineTotalAmount = dataIn.lineItemsTotal;
            const ItemExpress = dataIn.itemsAll.find(
                i => i.speciesItem === ORDER_SERVICE_TYPES[dataIn.serviceType].key
            );
            if (ItemExpress) {
                const itemExpressTotal = ItemExpress.priceTotal;
                lineTotalAmount -= itemExpressTotal;
            }
            return lineTotalAmount;
        },
    },
    itemsDiscount5: {
        prop: 'Details',
        propIn: (value, dataIn) => {
            const itemDiscount = dataIn.itemsAll.find(i => i.speciesItem === WP_DISCOUNT_5);
            return itemDiscount ? itemDiscount.priceTotal : false;
        },
    },
    itemsDiscount15: {
        prop: 'Details',
        propIn: (value, dataIn) => {
            const itemDiscount = dataIn.itemsAll.find(i => i.speciesItem === WP_DISCOUNT_15);
            return itemDiscount ? itemDiscount.priceTotal : false;
        },
    },

    qty: {
        prop: 'OrderedQty',
        propIn: (quantity, dataIn) => {
            let number = quantity.value;
            if (
                number >= 1 &&
                (dataIn.serviceType === ORDER_SERVICE_TYPES.guaranteed.name ||
                    dataIn.serviceType === ORDER_SERVICE_TYPES.express.name)
            ) {
                number -= 1;
            }

            return number;
        },
    },

    payByCheck: {
        prop: 'CheckOnly',
        propIn: propGetValue,
        propOut: value => ({
            value,
        }),
    },

    paid: {
        prop: 'Payments',
        propIn: value => !!(value && value.length),
    },
    status: {
        prop: 'Status',
        propIn: calculateOrderStatus,
    },
    customerEmail: {
        prop: 'customerEmail',
        propIn: propGetterSetter,
        propOut: propGetterSetter,
    },
    customerLocationId: {
        prop: 'customerLocationId',
        propIn: propGetterSetter,
        propOut: propGetterSetter,
    },
    userId: {
        prop: 'userId',
        propIn: propGetterSetter,
        propOut: propGetterSetter,
    },
    customerName: {
        prop: 'customerName',
        propIn: propGetterSetter,
        propOut: propGetterSetter,
    },
    tracking: {
        prop: 'Tracking',
        propIn: (tracking = []) =>
            tracking.map(trackingItem => ({
                id: trackingItem.id,
                inventoryId: trackingItem.InventoryID.value,
                orderLineNbr: trackingItem.SOLineNbr.value,
                tag: trackingItem.Tag.value,
                bagTag: trackingItem.BagTag.value,
                clientMark: trackingItem.ClientMark.value,
                govMark: trackingItem.GovMark.value,
            })),
    },
    formData: {
        prop: 'formData',
        propOut: propGetterSetter,
    },
    orderNbrs: {
        prop: 'orderNbrs',
        propOut: value => value,
    },
    firstName: {
        prop: 'firstName',
        propOut: value => ({
            value,
        }),
    },
    lastName: {
        prop: 'lastName',
        propOut: value => ({
            value,
        }),
    },
    token: {
        prop: 'token',
        propOut: propGetterSetter,
    },
    isDefaultLocation: {
        prop: 'isDefaultLocation',
        propOut: value => ({
            value,
        }),
    },
};

const list = () =>
    api.get('/order').then(({ data: orders = [] }) => {
        TDApi.get('/inventory/order/updateStatuses').catch();
        const res = orders.map(order => transformerIn(OrderFields, order));
        return res.sort((a, b) => (a.date > b.date ? -1 : 1));
    });

const listForAdmin = acumaticaCustomerId =>
    api.get(`/order/${acumaticaCustomerId}`).then(({ data: orders = [] }) => {
        const res = orders.map(order => transformerIn(OrderFields, order));
        return res.sort((a, b) => (a.date > b.date ? -1 : 1));
    });

const create = serviceType =>
    api.post(`/order?serviceType=${serviceType}`).then(order => transformerIn(OrderFields, order));

const updateOrderWithTrackingInfo = (order, trackingInfo) => {
    const orderData = transformerIn(OrderFields, order);
    const trackingDataUpdate = {
        id: order.id,
        Tracking: prepareTrackingObject(orderData, trackingInfo),
    };

    return api.put(`/order/${orderData.number}/WP/tracking`, trackingDataUpdate);
};

const createWebPro = (serviceType, isFrozenOrder, items) =>
    new Promise((resolve, reject) => {
        const acumaticaServiceType = ITEM_SERVICE_TYPES[serviceType].acumaticaType;
        let prepareData = { ServiceType: { value: acumaticaServiceType } };
        if (acumaticaServiceType === 'Rush' || acumaticaServiceType === 'Guaranteed') {
            prepareData = {
                ...prepareData,
                Details: [
                    {
                        ServiceType: { value: acumaticaServiceType },
                        InventoryID: { value: acumaticaServiceType },
                        OrderQty: { value: '1' },
                        ProdBin: { value: '99999' },
                        ProdStatus: { value: 'Complete' },
                    },
                    {
                        ServiceType: { value: acumaticaServiceType },
                        InventoryID: { value: isFrozenOrder ? WP_DISCOUNT_15 : WP_DISCOUNT_5 },
                        OrderQty: { value: '0' },
                        ProdBin: { value: '99999' },
                        ProdStatus: { value: 'Complete' },
                    },
                ],
            };
        } else {
            prepareData = {
                ...prepareData,
                Details: [
                    {
                        ServiceType: { value: acumaticaServiceType },
                        InventoryID: { value: isFrozenOrder ? WP_DISCOUNT_15 : WP_DISCOUNT_5 },
                        OrderQty: { value: '0' },
                        ProdBin: { value: '99999' },
                        ProdStatus: { value: 'Complete' },
                    },
                ],
            };
        }
        api.post(`/order/WP`, prepareData)
            .then(prepOrder => {
                const data = transformerInventoryToOrder(items);
                const orderNbr = prepOrder.OrderNbr.value;
                return api
                    .put(`/order/${orderNbr}/WP/details`, data)
                    .then(order => updateOrderWithTrackingInfo(order, data.trackingInfo));
            })
            .then(
                order => resolve(transformerIn(OrderFields, order)),
                error => reject(error)
            );
    });

const get = (id, type) =>
    api.get(`/order/${id}/${type}`).then(res => transformerIn(OrderFields, res));

const getForAdmin = (id, type) =>
    api.get(`/order/${id}/${type}/admin`).then(res => transformerIn(OrderFields, res));

const getPublicOrder = (id, customerId) =>
    api.get(`/orders/${id}/${customerId}`).then(res => transformerIn(OrderFields, res[0]));

const update = (id, type, values) =>
    new Promise((resolve, reject) => {
        const data = transformerOut(OrderFields, values);
        api.put(`/order/${id}/${type}`, data).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        );
    });

const updateDetails = (id, type, values) =>
    new Promise((resolve, reject) => {
        const data = transformerOut(OrderFields, values);
        api.put(`/order/${id}/${type}/details`, data).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        );
    });

const remove = (id, type) => api.delete(`/order/${id}/${type}`);

/* eslint no-promise-executor-return: "off" */
const payment = (id, type, values) =>
    new Promise((resolve, reject) =>
        api.post(`/order/${id}/${type}/payment`, values).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        )
    );

const check = (id, type, values) =>
    new Promise((resolve, reject) =>
        api.post(`/order/${id}/${type}/cheque`, values).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        )
    );

export const OrderService = {
    list,
    listForAdmin,
    get,
    getForAdmin,
    create,
    createWebPro,
    update,
    updateDetails,
    remove,
    payment,
    check,
    getPublicOrder,
};
