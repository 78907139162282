/* eslint-disable import/no-cycle */
import { AuthConstants as constants } from '../constants';
import { AuthService as service, LocalService } from '../services';
import { showResponseError, history, findPostAuthRedirectPath } from '../helpers';
import { UserStatus } from '../models';

import { NotificationHelper } from '../helpers/notification.helper';

const loginRequest = user => ({ type: constants.LOGIN_REQUEST, user });
const loginSuccess = user => ({ type: constants.LOGIN_SUCCESS, user });
const logoutSuccess = () => ({ type: constants.LOGOUT_SUCCESS });
const loginFailure = error => ({ type: constants.LOGIN_FAILURE, error });
const profileChanged = user => ({ type: constants.PROFILE_CHANGED, user });
const userAcumaticaChanged = acumaticaUser => ({
    type: constants.PROFILE_ACU_CHANGED,
    acumaticaUser,
});
const loginAuto = user => ({ type: constants.LOGIN_AUTO, user });

const login = (credentials, referrer) => dispatch => {
    dispatch(loginRequest(credentials));

    return service.login(credentials).then(
        user => {
            dispatch(loginSuccess(user));
            const path = findPostAuthRedirectPath(referrer, user.role, user.acumaticaCustomerId);
            history.push(path);
        },
        error => {
            dispatch(loginFailure(error));
            showResponseError(error);
        }
    );
};

const register = data =>
    service.register(data).then(
        res => res,
        error => showResponseError(error)
    );

const { signUpTokenValidate } = service;

const logout = () => dispatch => {
    const token = LocalService.token();
    if (!token) dispatch(logoutSuccess());

    return service.logout().then(
        () => {
            dispatch(logoutSuccess());
            history.push('/');
            NotificationHelper('success', 5, 'Logged Out...', 'You have been logged out!');
        },
        () => dispatch(logoutSuccess())
    );
};

export const getLoggedUser = () => dispatch => {
    const token = LocalService.token();
    if (!token) {
        return Promise.resolve();
    }
    dispatch(loginRequest({}));
    return service.getLoggedUser().then(
        data => {
            if (data.status === UserStatus.INACTIVE) {
                return dispatch(logoutSuccess());
            }
            dispatch(profileChanged(data));
            return data;
        },
        error => dispatch(loginFailure(error))
    );
};

const getValidToken = token => () => service.checkForValidToken(token);

const updateLoggedUser = values => dispatch =>
    service.updateLoggedUser(values).then(data => {
        dispatch(profileChanged(data));
        dispatch(userAcumaticaChanged(data));
        NotificationHelper('success', 5, '', 'Profile Saved');
        return data;
    }, showResponseError);

const passwordChange = data =>
    service.passwordChange(data).catch(error => showResponseError(error));

const passwordChangeRequest = email =>
    service.passwordChangeRequest(email).catch(error => showResponseError(error));

const remove = id => service.remove(id).then(data => data, showResponseError);

const list = (...params) => service.list(...params).then(data => data, showResponseError);
const update = (...args) => service.update(...args).then(data => data, showResponseError);

const link = (...args) => service.link(...args).then(data => data, showResponseError);

const sendActivation = (...args) => service.sendActivation(...args).catch(showResponseError);

const changeEmail = (...args) => service.changeEmail(...args).then(data => data, showResponseError);

export const AuthActions = {
    login,
    logout,
    register,
    loginSuccess,
    loginAuto,
    changeEmail,
    logoutSuccess,
    signUpTokenValidate,
    passwordChangeRequest,
    passwordChange,
    getLoggedUser,
    updateLoggedUser,
    remove,
    getValidToken,
    list,
    update,
    link,
    sendActivation,
};
