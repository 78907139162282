import { AuthConstants as constants, OrderConstants } from '../constants';
import { LocalService } from '../services/local.service';
// eslint-disable-next-line import/no-cycle
import { RoleIds } from '../models';

const loggingIn = !!LocalService.token();

const initialState = Object.freeze({
    loggedIn: false,
    loggingIn,
    user: { role: RoleIds.PUBLIC },
    locations: [],
    acumaticaUser: {},
});

const initialStateAuto = Object.freeze({
    loggedIn: true,
    loggingIn,
    user: { role: RoleIds.CLIENT },
    locations: [],
    acumaticaUser: {},
});

const authentication = (state = initialState, action) => {
    switch (action.type) {
        case constants.LOGIN_REQUEST:
            return {
                ...initialState,
                loggingIn: true,
                loggedIn: false,
                user: {
                    ...state.user,
                    ...action.user,
                },
            };
        case constants.LOGIN_SUCCESS: {
            LocalService.token(action.user.token);
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: true,
                user: {
                    ...state.user,
                    ...action.user,
                },
            };
        }
        case constants.LOGIN_AUTO: {
            LocalService.token(action.user.token);
            return {
                ...initialStateAuto,
                loggingIn: false,
                loggedIn: true,
                user: {
                    roleId: RoleIds.CLIENT,
                    ...initialStateAuto.user,
                    ...action.user,
                },
            };
        }
        case constants.LOGIN_FAILURE:
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false,
                loginError: action.error,
            };
        case constants.PROFILE_CHANGED:
            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                user: {
                    ...state.user,
                    ...action.user,
                },
            };
        case OrderConstants.ORDER_PAID: {
            const { user } = state;
            const {
                order: { number },
            } = action;
            if (user.orderId === number) return { ...state, user: { ...user, orderId: '' } };
            if (user.expressOrderId === number)
                return { ...state, user: { ...user, expressOrderId: '' } };
            return { ...state };
        }
        case OrderConstants.ORDER_LOADING_ERROR: {
            const { user } = state;
            const num = action.error ? action.error.number : '';
            if (user.orderId === num) return { ...state, user: { ...user, orderId: '' } };
            if (user.expressOrderId === num)
                return { ...state, user: { ...user, expressOrderId: '' } };
            return { ...state };
        }
        case constants.PROFILE_ACU_CHANGED:
            return {
                ...state,
                acumaticaUser: action.acumaticaUser,
            };
        case constants.LOCATIONS_LOADED:
            return {
                ...state,
                locations: action.locations,
            };
        case constants.LOCATION_ADDED:
            return {
                ...state,
                locations: [...state.locations, action.location],
            };

        case constants.LOGOUT_SUCCESS: {
            LocalService.token(false);
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false,
            };
        }

        default: {
            return state;
        }
    }
};

export { authentication };
