import { DiscountConstants as constants } from '../constants';

const initialState = Object.freeze({
    discounts: {},
    loading: false,
    error: false,
});

const discount = (state = initialState, action) => {
    switch (action.type) {
        case constants.DISCOUNTS_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.DISCOUNTS_LOADING_START: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }
        case constants.DISCOUNTS_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.DISCOUNTS_LOADED: {
            return {
                ...state,
                discounts: action.discounts,
                loading: false,
                error: false,
            };
        }
        default:
            return state;
    }
};

export { discount };
