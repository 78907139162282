export const AuthConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_AUTO: 'LOGIN_AUTO',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH_LOGOUT_FAILURE',
    PROFILE_CHANGED: 'AUTH_USER_PROFILE_CHANGED',
    PROFILE_ACU_CHANGED: 'AUTH_USER_ACU_CHANGED',
    LOCATIONS_LOADED: 'AUTH_LOCATIONS_LOADED',
    LOCATION_ADDED: 'AUTH_LOCATION_ADDED',
    CUSTOMER_FILE_UPLOAD: 'CUSTOMER_FILE_UPLOAD',
};

export const AdminConstants = {
    ADMIN_USER_SELECTED: 'ADMIN_USER_SELECTED',
    ADMIN_USER_DESELECTED: 'ADMIN_USER_DESELECTED',
    ADMIN_USER_LOADING_START: 'ADMIN_USER_LOADING_START',
    ADMIN_USER_LOADING_ERROR: 'ADMIN_USER_LOADING_ERROR',
    ADMIN_USER_LOADED: 'ADMIN_USER_LOADED',
};

export const OrderDraftConstants = {
    ORDER_DRAFT_CLEAR: 'ORDER_DRAFT_CLEAR',
    ORDER_DRAFT_LOADING_START: 'ORDER_DRAFT_LOADING_START',
    ORDER_DRAFT_LOADING_ERROR: 'ORDER_DRAFT_LOADING_ERROR',
    ORDER_DRAFT_LOADED: 'ORDER_DRAFT_LOADED',
    ORDER_DRAFT_DELETING_START: 'ORDER_DRAFT_DELETING_START',
    ORDER_DRAFT_DELETING_ERROR: 'ORDER_DRAFT_DELETING_ERROR',
    ORDER_DRAFT_DELETED: 'ORDER_DRAFT_DELETED',
    ORDER_DRAFT_CREATED: 'ORDER_DRAFT_CREATED',
    ORDER_DRAFT_CREATING_START: 'ORDER_DRAFT_CREATING_START',
    ORDER_DRAFT_CREATING_ERROR: 'ORDER_DRAFT_CREATING_ERROR',
    ORDER_DRAFT_ITEMS_LOADING_START: 'ORDER_DRAFT_ITEMS_LOADING_START',
    ORDER_DRAFT_ITEMS_LOADING_ERROR: 'ORDER_DRAFT_ITEMS_LOADING_ERROR',
    ORDER_DRAFT_ITEMS_LOADED: 'ORDER_DRAFT_ITEMS_LOADED',
};

export const WOrderDraftConstants = {
    WORDER_DRAFT_CLEAR: 'WORDER_DRAFT_CLEAR',
    WORDER_DRAFT_LOADING_START: 'WORDER_DRAFT_LOADING_START',
    WORDER_DRAFT_LOADING_ERROR: 'WORDER_DRAFT_LOADING_ERROR',
    WORDER_DRAFT_LOADED: 'WORDER_DRAFT_LOADED',
    WORDER_DRAFT_DELETING_START: 'WORDER_DRAFT_DELETING_START',
    WORDER_DRAFT_DELETING_ERROR: 'WORDER_DRAFT_DELETING_ERROR',
    WORDER_DRAFT_DELETED: 'WORDER_DRAFT_DELETED',
    WORDER_DRAFT_CREATED: 'WORDER_DRAFT_CREATED',
    WORDER_DRAFT_CREATING_START: 'WORDER_DRAFT_CREATING_START',
    WORDER_DRAFT_CREATING_ERROR: 'WORDER_DRAFT_CREATING_ERROR',
    WORDER_DRAFT_ITEMS_LOADING_START: 'WORDER_DRAFT_ITEMS_LOADING_START',
    WORDER_DRAFT_ITEMS_LOADING_ERROR: 'WORDER_DRAFT_ITEMS_LOADING_ERROR',
    WORDER_DRAFT_ITEMS_LOADED: 'WORDER_DRAFT_ITEMS_LOADED',
};

export const OrderConstants = {
    ORDER_SELECTED: 'ORDER_SELECTED',
    ORDER_DESELECTED: 'ORDER_DESELECTED',

    ORDERS_LOADING_START: 'ORDERS_LOADING_START',
    ORDERS_LOADING_ERROR: 'ORDERS_LOADING_ERROR',
    ORDERS_LOADED: 'ORDERS_LOADED',
    ORDERS_CLEAR: 'ORDERS_CLEAR',

    ORDER_UPDATING_START: 'ORDER_UPDATING_START',
    ORDER_UPDATING_ERROR: 'ORDER_UPDATING_ERROR',
    ORDER_UPDATED: 'ORDER_UPDATED',
    ORDER_PAID: 'ORDER_PAID',

    ORDER_LOADING_START: 'ORDER_LOADING_START',
    ORDER_LOADING_ERROR: 'ORDER_LOADING_ERROR',
    ORDER_LOADED: 'ORDER_LOADED',

    ORDER_CREATING_START: 'ORDER_CREATE_START',
    ORDER_CREATING_ERROR: 'ORDER_CREATE_ERROR',
    ORDER_CREATED: 'ORDER_CREATED',

    ORDER_CLEAR: 'ORDER_CLEAR',
};

export const BatchOrderConstants = {
    BATCH_ORDER_UPDATING_START: 'BATCH_ORDER_UPDATING_START',
    BATCH_ORDER_UPDATING_ERROR: 'BATCH_ORDER_UPDATING_ERROR',
    BATCH_ORDER_UPDATED: 'BATCH_ORDER_UPDATED',
    BATCH_ORDER_PAID: 'BATCH_ORDER_PAID',

    BATCH_ORDER_LOADING_START: 'BATCH_ORDER_LOADING_START',
    BATCH_ORDER_LOADING_ERROR: 'BATCH_ORDER_LOADING_ERROR',
    BATCH_ORDER_LOADED: 'BATCH_ORDER_LOADED',

    BATCH_ORDER_CREATING_START: 'BATCH_ORDER_CREATE_START',
    BATCH_ORDER_CREATING_ERROR: 'BATCH_ORDER_CREATE_ERROR',
    BATCH_ORDER_CREATED: 'BATCH_ORDER_CREATED',

    BATCH_ORDER_CLEAR: 'BATCH_ORDER_CLEAR',
};

export const InventoryConstants = {
    INVENTORY_CLEAR: 'INVENTORY_CLEAR',
    INVENTORY_LOADING_START: 'INVENTORY_LOADING_START',
    INVENTORY_LOADING_ERROR: 'INVENTORY_LOADING_ERROR',
    INVENTORY_LOADED: 'INVENTORY_LOADED',
    INVENTORY_ITEM_LOADING_START: 'INVENTORY_ITEM_LOADING_START',
    INVENTORY_ITEM_LOADING_ERROR: 'INVENTORY_ITEM_LOADING_ERROR',
    INVENTORY_ITEM_LOADED: 'INVENTORY_ITEM_LOADED',
    INVENTORY_ITEM_UNLOADED: 'INVENTORY_ITEM_UNLOADED',
    INVENTORY_ITEM_CREATING_START: 'INVENTORY_ITEM_CREATE_START',
    INVENTORY_ITEM_CREATING_ERROR: 'INVENTORY_ITEM_CREATE_ERROR',
    INVENTORY_ITEM_CREATED: 'INVENTORY_ITEM_CREATED',
    INVENTORY_ITEM_EDITING_START: 'INVENTORY_ITEM_EDIT_START',
    INVENTORY_ITEM_EDITING_ERROR: 'INVENTORY_ITEM_eDIT_ERROR',
    INVENTORY_ITEM_EDITED: 'INVENTORY_ITEM_EDITED',
    INVENTORY_ITEM_DELETING_START: 'INVENTORY_ITEM_DELETE_START',
    INVENTORY_ITEM_DELETING_ERROR: 'INVENTORY_ITEM_DELETE_ERROR',
    INVENTORY_ITEM_DELETED: 'INVENTORY_ITEM_DELETED',
    INVENTORY_ITEM_UPDATING_STATUS_START: 'INVENTORY_ITEM_UPDATING_STATUS_START',
    INVENTORY_ITEM_UPDATING_STATUS_ERROR: 'INVENTORY_ITEM_UPDATING_STATUS_ERROR',
    INVENTORY_ITEM_STATUS_UPDATED: 'INVENTORY_ITEM_STATUS_UPDATED',
    INVENTORY_ADD_FILTERS: 'INVENTORY_ADD_FILTERS',
    INVENTORY_CLEAR_FILTERS: 'INVENTORY_CLEAR_FILTERS',
    INVENTORY_ITEM_IMAGE_DELETING_START: 'INVENTORY_ITEM_IMAGE_DELETING_START',
    INVENTORY_ITEM_IMAGE_DELETING_ERROR: 'INVENTORY_ITEM_IMAGE_DELETING_ERROR',
    INVENTORY_ITEM_IMAGE_DELETED: 'INVENTORY_ITEM_IMAGE_DELETED',

    INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADING_START:
        'INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADING_START',
    INVENTORY_ITEM_FISH_AND_WILDLIFE_ERROR: 'INVENTORY_ITEM_FISH_AND_WILDLIFE_ERROR',
    INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADED: 'INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADED',
    INVENTORY_SCANNED_ITEMS_UPDATE: 'INVENTORY_SCANNED_ITEMS_UPDATE',
    INVENTORY_SCANNED_ITEMS_RESET: 'INVENTORY_SCANNED_ITEMS_RESET',
    INVENTORY_ITEMS_IMPORTING_START: 'INVENTORY_ITEMS_IMPORTING_START',
    INVENTORY_ITEMS_IMPORTING_ERROR: 'INVENTORY_ITEMS_IMPORTING_ERROR',
    INVENTORY_ITEMS_IMPORTED: 'INVENTORY_ITEMS_IMPORTED',
    INVENTORY_ITEMS_IMPORTED_CLEAR: 'INVENTORY_ITEMS_IMPORTED_CLEAR',
    INVENTORY_ITEM_FEEDBACK_CREATING_START: 'INVENTORY_ITEM_FEEDBACK_CREATING_START',
    INVENTORY_ITEM_FEEDBACK_CREATING_ERROR: 'INVENTORY_ITEM_FEEDBACK_CREATING_ERROR',
    INVENTORY_ITEM_FEEDBACK_CREATED: 'INVENTORY_ITEM_FEEDBACK_CREATED',
};

export const ProductConstants = {
    ALL_PRODUCTS_LOADED: 'ALL_PRODUCTS_LOADED',
    ALL_PRODUCTS_LOADING_START: 'ALL_PRODUCTS_LOADING_START',
    ALL_PRODUCTS_LOADING_ERROR: 'ALL_PRODUCTS_LOADING_ERROR',

    NONFROZEN_PRODUCTS_LOADED: 'NONFROZEN_PRODUCTS_LOADED',
    NONFROZEN_PRODUCTS_LOADING_START: 'NONFROZEN_PRODUCTS_LOADING_START',
    NONFROZEN_PRODUCTS_LOADING_ERROR: 'NONFROZEN_PRODUCTS_LOADING_ERROR',

    FROZEN_PRODUCTS_LOADED: 'FROZEN_PRODUCTS_LOADED',
    FROZEN_PRODUCTS_LOADING_START: 'FROZEN_PRODUCTS_LOADING_START',
    FROZEN_PRODUCTS_LOADING_ERROR: 'FROZEN_PRODUCTS_LOADING_ERROR',

    PRODUCTS_NON_STOCK_LOADED: 'PRODUCTS_NON_STOCK_LOADED',
    PRODUCTS_NON_STOCK_LOADING_START: 'PRODUCTS_NON_STOCK_LOADING_START',
    PRODUCTS_NON_STOCK_LOADING_ERROR: 'PRODUCT_NON_STOCK_LOADING_ERROR',
};

export const ClientConstants = {
    CLIENTS_CLEAR: 'CLIENTS_CLEAR',
    CLIENTS_LOADING_START: 'CLIENTS_LOADING_START',
    CLIENTS_LOADING_ERROR: 'CLIENTS_LOADING_ERROR',
    CLIENTS_LOADED: 'CLIENTS_LOADED',
};

export const DiscountConstants = {
    DISCOUNTS_CLEAR: 'DISCOUNTS_CLEAR',
    DISCOUNTS_LOADING_START: 'DISCOUNTS_LOADING_START',
    DISCOUNTS_LOADING_ERROR: 'DISCOUNTS_LOADING_ERROR',
    DISCOUNTS_LOADED: 'DISCOUNTS_LOADED',
};

export const GONEXT = 'GOTO_NEXT';
