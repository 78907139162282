import { notification } from 'antd';

export function NotificationHelper(type, duration, message, description) {
    switch (type) {
        case 'error':
            notification.error({
                duration,
                message,
                description,
            });
            break;
        case 'success':
            notification.success({
                duration,
                message,
                description,
            });
            break;
        case 'warning':
            notification.warning({
                duration,
                message,
                description,
            });
            break;
        case 'open':
            notification.open({
                duration,
                message,
                description,
            });
            break;
        default:
            break;
    }
}
