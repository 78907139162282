import { ReactComponent as FortyDaysServiceIcon } from '../assets/fortyDays-service.svg';
import { ReactComponent as NinetyDaysServiceIcon } from '../assets/ninetyDays-service.svg';
import { ReactComponent as StandardServiceIcon } from '../assets/standard-service.svg';

import { ReactComponent as FrozenIcon } from '../assets/frozen.svg';
import { ReactComponent as SaltedIcon } from '../assets/salted.svg';

export const ITEM_SERVICE_TYPES = {
    0: {
        label: 'Standard',
        icon: StandardServiceIcon,
        acumaticaType: 'Standard',
        phpServiceType: 'Standard',
        value: 0,
        key: 'regular',
        surchargeText: '0%',
    },
    1: {
        label: '90-Day Guarantee, 10% surcharge',
        icon: NinetyDaysServiceIcon,
        acumaticaType: 'Guaranteed',
        phpServiceType: 'Guaranteed90Days',
        value: 1,
        key: 'guaranteed',
        surchargeText: '10%',
    },
    2: {
        label: '40-Day Rush, 50% surcharge',
        icon: FortyDaysServiceIcon,
        acumaticaType: 'Rush',
        phpServiceType: 'Rush40Days',
        value: 2,
        key: 'express',
        surchargeText: '50%',
    },
};

export const ITEM_TYPES = {
    0: {
        title: 'Salted',
        icon: SaltedIcon,
        value: false,
    },
    1: {
        title: 'Frozen',
        icon: FrozenIcon,
        value: true,
    },
};
