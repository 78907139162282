/* eslint-disable import/no-cycle */
import { TDApi, api } from './_api';
import { transformerIn, transformerOut, OrderFields } from './order.service';

// retry promise N times while waiting between each attempt
// until it resolves or rejects on the last attempt
const retryWithWait = (callback, times, wait) =>
    new Promise((resolve, reject) => {
        let error;
        const attempt = count =>
            callback()
                .then(resolve)
                .catch(err => {
                    // if (failOnError && failOnError(err)) {
                    //     reject(err);
                    // }
                    error = err;
                    if (count === times) {
                        reject(error);
                    } else {
                        setTimeout(() => attempt(count + 1), wait);
                    }
                });
        attempt(1);
    });

const get = id =>
    retryWithWait(
        async () => {
            try {
                const batch = await TDApi.get(`/batchOrder/${id}`);
                if (batch && batch.nrOrders === batch.orders.length) {
                    const promises = batch.orders.map(order =>
                        api
                            .get(`/order/${order.orderNbr}/WP`)
                            .then(result => transformerIn(OrderFields, result))
                    );
                    const acumaticaOrders = await Promise.all(promises);
                    batch.acumaticaOrders = acumaticaOrders;
                    batch.totalPayment = batch.acumaticaOrders.reduce(
                        (accumulator, order) => accumulator + order.total,
                        0
                    );
                    batch.orderDetails = batch.orders;
                    batch.orders = acumaticaOrders;

                    return batch;
                }
                throw new Error('Batch order not ready');
            } catch (err) {
                throw new Error(err);
            }
        },
        120,
        5000
        // err => err.message === 'Batch order not ready'
    );

const create = values => TDApi.post('/batchOrder', values);
const update = async values => {
    const data = transformerOut(OrderFields, values);
    await TDApi.put(`/batchOrder/${values.id}`, data);

    return retryWithWait(
        async () => {
            try {
                const batch = await TDApi.get(`/batchOrder/${values.id}`);
                if (batch && batch.step.toString() === values.activeStep.toString()) {
                    const promises = batch.orders.map(order =>
                        api
                            .get(`/order/${order.orderNbr}/WP`)
                            .then(result => transformerIn(OrderFields, result))
                    );
                    const acumaticaOrders = await Promise.all(promises);
                    batch.acumaticaOrders = acumaticaOrders;
                    batch.totalPayment = batch.acumaticaOrders.reduce(
                        (accumulator, order) => accumulator + order.total,
                        0
                    );
                    batch.orderDetails = batch.orders;
                    batch.orders = acumaticaOrders;
                    return batch;
                }
                throw new Error('Batch order not ready');
            } catch (err) {
                throw new Error(err);
            }
        },
        120,
        5000
    );
};

/* eslint no-promise-executor-return: "off" */
const payment = (id, type, values) =>
    new Promise((resolve, reject) =>
        api.post(`/order/${id}/${type}/batchPayment`, values).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        )
    );

export const BatchOrderService = {
    get,
    create,
    update,
    payment,
};
