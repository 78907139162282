/* eslint-disable import/no-cycle */
import { TDApi as api } from './_api';

const get = () => api.get('/orderDraft');
const create = values => api.post('/orderDraft', values);
const deleteOrderDraft = () => api.delete('/orderDraft');
const getItems = () => api.get('/orderDraft/items');

export const OrderDraftService = {
    get,
    create,
    deleteOrderDraft,
    getItems,
};
