import { OrderDraftConstants as constants } from '../constants';

const initialState = Object.freeze({
    orderDraft: null,
    loading: false,
    error: false,
    orderDraftDeleting: false,
    orderDraftItems: [],
    orderDraftItemsLoading: false,
});

const orderDraft = (state = initialState, action) => {
    switch (action.type) {
        case constants.ORDER_DRAFT_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.ORDER_DRAFT_LOADING_START: {
            return {
                ...state,
                orderDraft: null,
                loading: true,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.ORDER_DRAFT_LOADED: {
            return {
                ...state,
                orderDraft: action.orderDraft,
                loading: false,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_DELETING_START: {
            return {
                ...state,
                orderDraftDeleting: true,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_DELETING_ERROR: {
            return {
                ...state,
                orderDraftDeleting: false,
                error: action.error,
            };
        }
        case constants.ORDER_DRAFT_DELETED: {
            return {
                ...state,
                orderDraft: null,
                orderDraftDeleting: false,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_CREATED: {
            return {
                ...state,
                orderDraft: action.orderDraft,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_CREATING_START: {
            return {
                ...state,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_CREATING_ERROR: {
            return {
                ...state,
                error: action.error,
            };
        }
        case constants.ORDER_DRAFT_ITEMS_LOADING_START: {
            return {
                ...state,
                orderDraftItems: [],
                orderDraftItemsLoading: true,
                error: false,
            };
        }
        case constants.ORDER_DRAFT_ITEMS_LOADING_ERROR: {
            return {
                ...state,
                orderDraftItemsLoading: false,
                error: action.error,
            };
        }
        case constants.ORDER_DRAFT_ITEMS_LOADED: {
            return {
                ...state,
                orderDraftItems: action.orderDraftItems,
                orderDraftItemsLoading: false,
                error: false,
            };
        }
        default:
            return state;
    }
};

export { orderDraft };
