/* eslint-disable import/no-cycle */
import { TDApi as api } from './_api';

const create = values => api.post('/worderDraft', values);
const deleteWOrderDraft = serviceTypeId => api.delete(`/worderDraft/serviceType/${serviceTypeId}`);
const get = serviceTypeId => api.get(`/worderDraft/serviceType/${serviceTypeId}`);

export const WOrderDraftService = {
    get,
    create,
    deleteWOrderDraft,
};
