export const BatchOrderStatus = {
    Incomplete: 0,
    OrderDetails: 1,
    License: 2,
    LaceyAct: 3,
    FinishedOrderShipping: 4,
    Payment: 5,
    ShipingToTanery: 6,
    TrackYourOrder: 7,
};
