import { ProductConstants as constants } from '../constants';

const initialState = Object.freeze({
    allLoading: false,
    nonFrozenLoading: false,
    frozenLoading: false,

    species: [],
    speciesItems: [],

    error: false,
    frozenError: false,
    nonFrozenError: false,

    allProducts: {},
    frozenProducts: {},
    nonFrozenProducts: {},

    nonStockItems: [],
    nonStockItemsLoading: false,
    nonStockItemsError: false,
});

const products = (state = initialState, action) => {
    switch (action.type) {
        case constants.ALL_PRODUCTS_LOADING_START: {
            return {
                ...state,
                allLoading: true,
            };
        }
        case constants.ALL_PRODUCTS_LOADING_ERROR: {
            return {
                ...state,
                allLoading: false,
                error: action.error,
            };
        }
        case constants.ALL_PRODUCTS_LOADED: {
            return {
                ...state,
                allLoading: false,
                allProducts: action.products,
            };
        }
        case constants.FROZEN_PRODUCTS_LOADING_START: {
            return {
                ...state,
                frozenLoading: true,
            };
        }
        case constants.FROZEN_PRODUCTS_LOADING_ERROR: {
            return {
                ...state,
                frozenLoading: false,
                frozenError: action.error,
            };
        }
        case constants.FROZEN_PRODUCTS_LOADED: {
            return {
                ...state,
                frozenLoading: false,
                frozenProducts: action.products,
            };
        }
        case constants.NONFROZEN_PRODUCTS_LOADING_START: {
            return {
                ...state,
                nonFrozenLoading: true,
            };
        }
        case constants.NONFROZEN_PRODUCTS_LOADING_ERROR: {
            return {
                ...state,
                nonFrozenLoading: false,
                nonFrozenError: action.error,
            };
        }
        case constants.NONFROZEN_PRODUCTS_LOADED: {
            return {
                ...state,
                nonFrozenLoading: false,
                nonFrozenProducts: action.products,
            };
        }
        case constants.PRODUCTS_NON_STOCK_LOADING_START: {
            return {
                ...state,
                nonStockItemsLoading: true,
            };
        }
        case constants.PRODUCTS_NON_STOCK_LOADING_ERROR: {
            return {
                ...state,
                nonStockItemsLoading: false,
                nonStockItemsError: action.error,
            };
        }
        case constants.PRODUCTS_NON_STOCK_LOADED: {
            return {
                ...state,
                nonStockItemsLoading: false,
                nonStockItems: action.items,
            };
        }
        default:
            return state;
    }
};

export { products };
