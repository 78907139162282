import { notification } from 'antd';

export const toQueryParams = params =>
    Object.keys(params)
        .map(k => {
            let value = params[k];
            if (Object.isObject(value)) {
                if (Object.isEmpty(value)) {
                    value = '';
                } else value = JSON.stringify(value);
            }
            if (value) {
                return `${k}=${value}`;
            }
            return '';
        })
        .join('&');

export const getHashValue = key => {
    const matches = window.location.hash.match(new RegExp(`${key}=([^&]*)`));

    return matches ? matches[1] : null;
};

export const getHashUrl = () => {
    /* eslint prefer-regex-literals: "off" */
    const matches = window.location.hash.match(new RegExp('#([^&]*)'));

    return matches ? matches[1] : null;
};

const updating = false;
export const onPriceClick = url => () => {
    if (updating) {
        notification.info({
            message: 'Pricelist is being updated...',
            description: 'We are updating our pricelist. Please, try again later.',
            placement: 'topRight',
        });
    } else {
        window.open(url, '_blank');
    }
};

export const getQueryStringValue = key => {
    const encodedKey = encodeURIComponent(key).replace(/[.+*]/g, '\\$&');
    const re = new RegExp(`^(?:.*[&\\?]${encodedKey}(?:\\=([^&]*))?)?.*$`, 'i');
    return decodeURIComponent(window.location.search.replace(re, '$1'));
};
