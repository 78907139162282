/* eslint-disable import/no-cycle */

import { api } from './_api';
import { toQueryParams } from '../helpers';
import { AuthService } from './auth.service';

export const transformerLocationIn = item => {
    const {
        LocationContact: { Address, Email, Phone1, DisplayName },
    } = item;
    return {
        value: item.LocationID.value,
        label: item.LocationName.value,
        address: {
            address1: Address.AddressLine1.value,
            address2: Address.AddressLine2.value,
            city: Address.City.value,
            state: Address.State.value,
            zip: Address.PostalCode.value,
            phoneNumber: Phone1.value,
            email: Email.value,
            companyName: DisplayName.value,
        },
    };
};

export const transformerLocationsIn = data => data.map(item => transformerLocationIn(item));

export const transformerLocationOut = data => {
    const dataOut = Object.cleanUp(data);
    return {
        LocationID: { value: dataOut.id },
        Customer: { value: dataOut.acumaticaCustomerId },
        AddressOverride: { value: true },
        ContactOverride: { value: true },
        Insurance: { value: false },
        LocationName: { value: dataOut.addressName },
        LocationContact: {
            Address: {
                AddressLine1: { value: dataOut.address1 },
                AddressLine2: dataOut.address2 && { value: dataOut.address2 },
                City: { value: dataOut.city },
                State: { value: dataOut.state },
                PostalCode: { value: dataOut.zip },
            },
            Email: dataOut.email && { value: dataOut.email },
            Phone1: { value: dataOut.phoneNumber },
            DisplayName: { value: dataOut.companyName },
        },
    };
};

const getAcumaticaUser = data =>
    api.get(`/customer?${toQueryParams(data)}`).then(res => AuthService.transformerIn(res));

const createAcumaticaUser = data =>
    api.post('/customer', data).then(res => AuthService.transformerIn(res));

const getAcumaticaUserLocations = () =>
    api.get('/customer/location').then(data => transformerLocationsIn(data.data));

const createAcumaticaUserLocation = values =>
    api
        .post('/customer/location', transformerLocationOut(values))
        .then(data => transformerLocationIn(data));

const getLocationsSo = (orderId, customerId) =>
    api
        .get(`/sales-order/${orderId}/${customerId}/location`)
        .then(data => transformerLocationsIn(data.data));

const createLocationSo = (orderId, customerId, data) =>
    api
        .post(`/sales-order/${orderId}/${customerId}/location`, transformerLocationOut(data))
        .then(res => transformerLocationIn(res));

export const CustomerService = {
    getAcumaticaUser,
    createAcumaticaUser,
    getAcumaticaUserLocations,
    createAcumaticaUserLocation,
    createLocationSo,
    getLocationsSo,
};
