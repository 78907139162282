import React, { useEffect, useCallback } from 'react';
import Spin from 'antd/es/spin';
import { Route, Switch, Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { getLoggedUser } from '../actions/auth.actions';
import { history } from '../helpers/history.helpers';
import ProtectedRoute from '../components/security/route';
import { RoleIds } from '../models';
import ScrollToTop from '../helpers/scroll.to.top';

const TRACKING_ID = 'G-4V3CNME8R0';

const basePath = process.env.REACT_APP_BASEPATH || '';

const PublicRoutes = React.lazy(() => import('./public'));
const AuthRoutes = React.lazy(() => import('./auth'));
const AdminRoutes = React.lazy(() => import('./admin'));
const ClientRoutes = React.lazy(() => import('./client'));

const hashLinkScroll = hash => {
    if (hash !== '') {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                window.scrollTo(0, element.offsetTop);
            }
        }, 0);
    }
};

function RootRoutes() {
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, []);

    const dispatch = useDispatch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
        history.listen(location => hashLinkScroll(location.hash));
        stableDispatch(getLoggedUser());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [stableDispatch]);

    return (
        <Router basename={basePath} history={history} onEnter={hashLinkScroll} spinning>
            <React.Suspense fallback={<Spin className="center" />}>
                <ScrollToTop />
                <Switch>
                    <Route path="/auth" exact={false} component={AuthRoutes} />
                    <ProtectedRoute
                        path="/(admincp|admincp/user/:id/inventory)/"
                        component={AdminRoutes}
                        access={[RoleIds.ADMIN]}
                    />
                    <ProtectedRoute
                        path="/(profile|orders/create|orders/create/:serviceType|orders/:number/:type|orders|inventory|proClientAccessInformation)/"
                        component={ClientRoutes}
                        access={[
                            RoleIds.ADMIN,
                            RoleIds.CLIENT,
                            RoleIds.CLIENT_LINKED,
                            RoleIds.PROCLIENT,
                        ]}
                    />

                    <Route component={PublicRoutes} />
                </Switch>
            </React.Suspense>
        </Router>
    );
}

export default RootRoutes;
