import { message, notification } from 'antd';

export * from './history.helpers';
export * from './auth.helpers';
export * from './query.helpers';
export * from './Price';

message.config({
    top: 200,
    duration: 1.5,
    maxCount: 1,
});

notification.config({
    placement: 'topRight',
    duration: 3,
});

export const showResponseError = error => {
    if (error && error.message) {
        message.error(`${error.source || ''} ${error.message} ${error.description || ''}`, 5);
    }
    return Promise.reject(new Error(error));
};

/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-unused-vars */

Object.equals = (x, y) => {
    if (x === y) return true;
    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    if (x.constructor !== y.constructor) return false;
    for (const p in x) {
        if (!x.hasOwnProperty(p)) continue;
        if (!y.hasOwnProperty(p)) return false;
        if (x[p] === y[p]) continue;
        if (typeof x[p] !== 'object') return false;
        if (!Object.equals(x[p], y[p])) return false;
    }

    for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    }
    return true;
};

Array.changeItem = (array, item, prop = 'id') => {
    const idx = array.findIndex(i => i[prop] === item[prop]);
    const arr = [...array];
    if (idx > -1) {
        arr.splice(idx, 1, item);
    } else arr.push(item);
    return arr;
};

Array.equals = (x, y) => {
    const type = Object.prototype.toString.call(x);
    if (type !== Object.prototype.toString.call(y)) return false;

    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    const xLen = x.length;
    const yLen = y.length;
    if (xLen !== yLen) return false;

    for (let i = 0; i < xLen; i++) {
        if (Object.equals(x[i], y[i]) === false) return false;
    }

    return true;
};

Array.filterFirst = (arr, value, prop) => {
    const res = arr.filter(item => item[prop] === value);
    if (res && res.length) {
        return res[0];
    }
    return undefined;
};

Array.remove = (arr, index) => arr.splice(index, 1);

Array.create = len => Array.from(new Array(len)).map((x, idx) => idx);

Object.isObject = x => Object.prototype.toString.call(x) === '[object Object]';

Object.isFunction = x => Object.prototype.toString.call(x) === '[object Function]';

Object.isEmpty = x => Object.equals(x, {});

Object.isDefined = x => typeof x !== 'undefined';

Object.isString = x => Object.prototype.toString.call(x) === '[object String]';

Object.contains = (x, y) => Object.keys(y).reduce((acc, key) => acc && y[key] === x[key], true);

Object.cleanUp = x =>
    Object.keys(x).reduce((acc, key) => {
        const acc1 = acc;
        if (x[key] !== undefined) acc1[key] = x[key];
        return acc1;
    }, {});

// Returns a function, that, as long as it continues to be invoked, will not be triggered.
// The function will be called after it stops being called for N milliseconds.

export function debounce(func, interval = 200) {
    let timeout;
    return function d1(...args) {
        const context = this;
        const later = function d2() {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, interval);
    };
}
