import { RoleIds } from '../models';
/* eslint-disable import/no-cycle */
export const findPostAuthRedirectPath = (referrer, userRole, acumaticaCustomerId) => {
    let redirectRoute = '/auth/register/identify';
    if (acumaticaCustomerId) {
        if (userRole === RoleIds.PROCLIENT) {
            redirectRoute = '/inventory';
        }
        if (userRole === RoleIds.CLIENT_LINKED || userRole === RoleIds.CLIENT) {
            redirectRoute = '/orders';
        }
    }

    if (userRole === RoleIds.ADMIN) {
        return '/admincp';
    }
    return referrer || redirectRoute;
};
