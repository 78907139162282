import React from 'react';
import PropTypes from 'prop-types';

function Price({ children, showSymbol, showDecimals, className }) {
    let price = parseFloat(children, 10);

    if (Number.isNaN(price)) {
        return children;
    }
    price = price.toLocaleString('en', {
        style: showSymbol ? 'currency' : undefined,
        currency: showSymbol ? 'USD' : undefined,
        maximumFractionDigits: showDecimals ? 2 : 0,
    });

    return <span className={className}>{price}</span>;
}

Price.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showDecimals: PropTypes.bool,
    showSymbol: PropTypes.bool,
};

Price.defaultProps = {
    className: '',
    children: 0,
    showDecimals: true,
    showSymbol: true,
};

export { Price };
