import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

const Paths = {
    home: '/',
    accessDenied: '/403',
    login: '/auth/login',
};

const hasAccess = (role = 0, access) => access.includes(role);

const redirect = (path, location) => (
    <Redirect
        to={{
            pathname: path,
            state: { from: location },
        }}
    />
);

const getRoute = (path, component, params) => (
    <Route
        key={path}
        render={() =>
            React.createElement(component, {
                params,
            })
        }
    />
);

function ProtectedRoute(props) {
    const location = useLocation();
    const { user, loggedIn, loggingIn, component, access, path, params, computedMatch } = props;

    const routeParams = { ...computedMatch.params, ...params };
    const granted = hasAccess(user.role, access);

    if (loggingIn) return null;

    if (loggedIn) {
        if (granted) return getRoute(path, component, routeParams);
        return redirect(Paths.accessDenied);
    }

    if (granted) return getRoute(path, component, routeParams);

    return redirect(Paths.login, location);
}

ProtectedRoute.propTypes = {
    user: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    loggingIn: PropTypes.bool.isRequired,
    access: PropTypes.array.isRequired,

    path: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
    params: PropTypes.object,
    computedMatch: PropTypes.object,
};

ProtectedRoute.defaultProps = {
    params: {},
    computedMatch: {},
};

const mapStateToProps = ({ authentication }) => ({
    user: authentication.user,
    loggedIn: authentication.loggedIn,
    loggingIn: authentication.loggingIn,
});

export default connect(mapStateToProps)(ProtectedRoute);
